<template>
  <div style="height: 150px;width: 240px;" ref="evaluateRadar"></div>
</template>

<script>
import 'echarts'
const echarts = require('echarts');
export default {
  name: "EvaluateRadar",
  props: {
    sourceData: {
      type: Array
    }
  },
  data() {
    return {};
  },
  methods: {
    initData() {
      let self = this;
      let myChart = echarts.init(this.$refs.evaluateRadar);
      let option = {
        title: {},
        legend: {},
        radar: {
          indicator: self.sourceData.map(item => {
            return {
              name: `${item.item}\n${item.score}`,
              max: 5
            }
          }),
          radius: '45%'
        },
        series: [{
          type: 'radar',
          areaStyle: {normal: {}},
          data: [
            {
              value: self.sourceData.map(item => item.score),
            },
          ]
        }]
      };
      myChart.setOption(option);
    }
  },
  mounted() {
    this.initData();
  }
};
</script>

<style scoped>

</style>
